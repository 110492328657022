import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {AppraisalDetailsWidgetPresenter} from './appraisal_details_widget_presenter';
import {Global} from '../../../../../business/global_provider';
import {QuestionSet} from '../../../../models/question_set';
import {isSet} from '../../../../../support/is_set';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';

interface OwnProps {
    global: Global;
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export const AppraisalDetailsWidget: React.FC<OwnProps> = observer(function AppraisalDetailsWidget(props) {
    const presenter = usePresenter(
        (container) =>
            new AppraisalDetailsWidgetPresenter(
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.support.surfaceAreaProvider(props.questionSet, props.appraisal),
                container.business.plotAreaProvider(props.appraisal, props.questionSet),
                container.business.energyLabelProvider(props.appraisal, props.questionSet),
                container.business.appraisalProvider,
                container.globalProvider
            )
    );

    return (
        <div className="col-12">
            <div className="widget">
                <div className="widget-title">
                    <h3>
                        <span className="ion-md-home" />
                        Kerngegevens taxatieobject
                    </h3>
                </div>
                <div className="widget-body">
                    <div className="row">
                        <div className="col-6">
                            <dl>
                                <dt>Adres</dt>
                                <dd>
                                    {props.global.objectImageUrl && (
                                        <span className="detail-group-image">
                                            <img src={props.global.objectImageUrl} />
                                        </span>
                                    )}
                                    {presenter.appraisal.address} {presenter.appraisal.houseNumber}{' '}
                                    {presenter.appraisal.letter}
                                    <br />
                                    {presenter.appraisal.postalCode} {presenter.appraisal.city}
                                    <br />
                                    {presenter.appraisal.country}
                                </dd>
                            </dl>
                        </div>
                        <div className="col-6">
                            <dl>
                                <dt>Woningtype</dt>
                                <dd>
                                    {isSet(presenter.appraisal.objectType)
                                        ? presenter.appraisal.objectType
                                        : presenter.appraisal.objectDisplayType ?? '-'}
                                </dd>
                            </dl>
                            <dl>
                                <dt>Datum opname</dt>
                                <dd>
                                    {isSet(presenter.appraisal.valuationDate)
                                        ? presenter.appraisal.valuationDate
                                        : 'Er is nog geen datum gepland.'}
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <dl>
                                <dt>Bouwjaar</dt>
                                <dd>
                                    {isSet(presenter.buildYear) ? presenter.buildYear : 'Er is geen bouwjaar bekend.'}
                                </dd>
                            </dl>
                        </div>
                        <div className="col-6">
                            <dl>
                                <dt>Energielabel</dt>
                                <dd>
                                    {isSet(presenter.energyLabel)
                                        ? presenter.energyLabel
                                        : 'Er is geen energielabel bekend.'}
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <dl>
                                <dt>Gebruiksoppervlakte wonen</dt>
                                <dd>
                                    {isSet(presenter.surfaceArea)
                                        ? presenter.surfaceArea + ' M²'
                                        : 'Er is geen gebruiksoppervlakte bekend.'}
                                </dd>
                            </dl>
                        </div>
                        <div className="col-6">
                            <dl>
                                <dt>Perceeloppervlakte</dt>
                                <dd>
                                    {isSet(presenter.plotArea)
                                        ? presenter.plotArea + ' M²'
                                        : 'Er is geen perceeloppervlakte bekend.'}
                                </dd>
                            </dl>
                        </div>
                    </div>
                    {isSet(presenter.volume) ? (
                        <div className="row">
                            <div className="col-6">
                                <dl>
                                    <dt>Bruto inhoud</dt>
                                    <dd>
                                        {isSet(presenter.volume)
                                            ? presenter.volume + ' m³'
                                            : 'Er is geen bruto inhoud bekend.'}
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
});
