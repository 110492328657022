import * as React from 'react';

import {detect} from 'detect-browser';

interface OwnProps {
    children?: React.ReactNode;
}

export class CompatibilityCheck extends React.Component<OwnProps> {
    private browser = detect();

    private hasFancyMethods() {
        return typeof Promise !== 'undefined' && Promise.toString().indexOf('[native code]') !== -1;
    }

    private isSupported(): boolean {
        switch (this.browser && this.browser.name) {
            case 'chrome':
            case 'firefox':
            case 'safari':
            case 'edge':
            case 'edge-chromium':
            case 'ios':
            case 'crios':
            case 'opera':
                return this.hasFancyMethods();
            case 'ie':
                return false;
            default:
                return false;
        }
    }

    public render() {
        if (!this.isSupported()) {
            return (
                <div className="empty-message appraise">
                    <h3>Je browser wordt niet ondersteund door taXapi</h3>
                    <span className="spacer-gradient">&nbsp;</span>
                    <p>
                        We maken gebruik van de laatste technologieën en veiligheidsvoorwaarden, zorg ervoor dat je
                        browser up-to-date is of installeer een moderne browser.
                    </p>
                </div>
            );
        }
        return this.props.children;
    }
}
