import $ from 'jquery';

export enum UserType {
    JUNIOR_APPRAISER = 'junior_appraiser',
    APPRAISER = 'appraiser',
}

export enum RoleType {
    ADMIN = 'admin',
    ALL = 'all',
    READ = 'read',
    CUSTOM = 'custom',
}

export function selectOption(select: JQuery<HTMLSelectElement>, value: string) {
    if (select) {
        if ($(select).hasClass('selectpicker')) {
            $(select).selectpicker('val', value);
            $(select).selectpicker('refresh');
        } else {
            $(select).find('option:selected').removeAttr('selected');
            $(select).find(`option[value="${value}"]`).attr('selected', 'selected');
        }
    }
}

export function disableSelect(select: JQuery<HTMLSelectElement>, readonly: boolean) {
    if ($(select).hasClass('selectpicker')) {
        if (readonly) {
            $(select).prop('property', true).addClass('disabled');
        } else {
            $(select).removeProp('property').removeClass('disabled');
            $(select).parent().removeClass('disabled');
        }
        $(select).selectpicker('refresh');
    } else {
        if (readonly) {
            $(select).attr('readonly', 'readonly').addClass('disabled');
        } else {
            $(select).removeAttr('readonly').removeClass('disabled');
        }
    }
}

export function disableField(formElement: JQuery<HTMLInputElement>, readonly: boolean) {
    if (readonly) {
        $(formElement).attr('readonly', 'readonly').addClass('disabled');
    } else {
        $(formElement).removeAttr('readonly').removeClass('disabled');
    }
}

import './create_user';
import './change_role';
