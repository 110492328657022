import * as React from 'react';

import {observer} from 'mobx-react';
import {Slider} from '../../../../../../components/slider';
import ReactSelect from 'react-select';
import {uppercaseFirstChar} from '../../../../../../../../../support/string';
import {ActiveSetDefinition} from '../reference_objects_question_presenter';
import {usePresenter} from '../../../../../../../../../support/presenter/use_presenter';
import {ReferenceFiltersPresenter} from './reference_filters_presenter';

export interface ReferenceFiltersData {
    distance: [number, number];
    surfaceArea: [number, number];
    plotArea: [number, number];
    buildYear: [number, number];
    ownership: {
        sorts: string[];
        durations: string[];
        other: string[];
    };
    otherAspects: {
        garden?: boolean;
        parking?: boolean;
        solar?: boolean;
        shed?: boolean;
        garage?: boolean;
    };
}

interface OwnProps {
    activeSetDefinition: ActiveSetDefinition;
    filters: ReferenceFiltersData;
    setFilters: (filters: ReferenceFiltersData) => void;
}

export const ReferenceFilters: React.FC<OwnProps> = observer(function ReferenceFilters(props) {
    const presenter = usePresenter(
        (container) => new ReferenceFiltersPresenter(props.activeSetDefinition, container.business.distanceProvider)
    );

    React.useEffect(() => {
        presenter.setActiveSet(props.activeSetDefinition);
    }, [props.activeSetDefinition]);

    const ownershipOptions = React.useMemo(() => {
        const groups: {label: string; options: {label: string; value: string}[]}[] = [];

        const sorts = ['volle eigendom', 'erfpacht', 'opstal', 'vruchtgebruik', 'onder erfpacht'];
        const durations = ['afgekocht', 'eeuwigdurend', 'voortdurend'];
        const other = ['zelfbewoning', 'kettingbeding'];

        groups.push({
            label: 'Soorten',
            options: sorts.map((sort) => ({label: uppercaseFirstChar(sort), value: `sorts.${sort}`})),
        });

        groups.push({
            label: 'Duur',
            options: durations.map((duration) => ({
                label: uppercaseFirstChar(duration),
                value: `durations.${duration}`,
            })),
        });

        groups.push({
            label: 'Overig',
            options: other.map((o) => ({label: uppercaseFirstChar(o), value: `other.${o}`})),
        });

        return groups;
    }, []);

    const selectedOwnershipOptions = React.useMemo(() => {
        const selectedOptions: {label: string; value: string}[] = [];

        props.filters.ownership.sorts.forEach((sort) => selectedOptions.push({label: sort, value: `sorts.${sort}`}));
        props.filters.ownership.durations.forEach((duration) =>
            selectedOptions.push({label: duration, value: `durations.${duration}`})
        );
        props.filters.ownership.other.forEach((o) => selectedOptions.push({label: o, value: `other.${o}`}));

        return selectedOptions;
    }, [props.filters, ownershipOptions]);

    const otherAspectsOptions = React.useMemo(
        () => [
            {label: 'Tuin', value: 'garden'},
            {label: 'Berging', value: 'shed'},
            {label: 'Garage', value: 'garage'},
            {label: 'Parkeerplaats', value: 'parking'},
            {label: 'Zonnepanelen', value: 'solar'},
        ],
        []
    );

    const selectedOtherAspectsOptions = React.useMemo(() => {
        const selectedOptions: {label: string; value: string}[] = [];

        if (props.filters.otherAspects.garden) {
            selectedOptions.push({label: 'Tuin', value: 'garden'});
        }
        if (props.filters.otherAspects.shed) {
            selectedOptions.push({label: 'Berging', value: 'shed'});
        }
        if (props.filters.otherAspects.garage) {
            selectedOptions.push({label: 'Garage', value: 'garage'});
        }
        if (props.filters.otherAspects.parking) {
            selectedOptions.push({label: 'Parkeerplaats', value: 'parking'});
        }
        if (props.filters.otherAspects.solar) {
            selectedOptions.push({label: 'Zonnepanelen', value: 'solar'});
        }

        return selectedOptions;
    }, [props.filters, otherAspectsOptions]);

    return (
        <div className="row">
            <div className="col-12 col-md-6 pb-3">
                <h3>Afstand tot het adres</h3>
                <div className="col-12">
                    <Slider
                        range
                        handleRender={(origin, props) =>
                            React.cloneElement<React.HTMLAttributes<HTMLDivElement>>(origin, {
                                children: `${props.value / 1000} km`,
                            })
                        }
                        defaultValue={props.filters.distance}
                        onAfterChange={(val) => props.setFilters({...props.filters, distance: val as [number, number]})}
                        min={0}
                        max={presenter.maxDistanceToAddress}
                        step={presenter.maxDistanceToAddress < 5000 ? 100 : 500}
                        marks={{
                            0: '0 km',
                            [presenter.maxDistanceToAddress]: `${presenter.maxDistanceToAddress / 1000} km`,
                        }}
                    />
                </div>
            </div>
            <div className="col-12 col-md-6 pb-3">
                <h3>Gebruiksoppervlakte wonen</h3>
                <div className="col-12">
                    <Slider
                        range
                        handleRender={(origin, props) =>
                            React.cloneElement<React.HTMLAttributes<HTMLDivElement>>(origin, {
                                children: `${props.value}m²`,
                            })
                        }
                        step={5}
                        defaultValue={props.filters.surfaceArea}
                        onAfterChange={(val) =>
                            props.setFilters({...props.filters, surfaceArea: val as [number, number]})
                        }
                        min={presenter.minSurfaceArea}
                        max={presenter.maxSurfaceArea}
                        marks={{
                            [presenter.minSurfaceArea]: `${presenter.minSurfaceArea} m²`,
                            [presenter.maxSurfaceArea]: `${presenter.maxSurfaceArea} m²`,
                        }}
                    />
                </div>
            </div>
            <div className="col-12 col-md-6 pb-3">
                <h3>Perceeloppervlakte</h3>
                <div className="col-12">
                    <Slider
                        range
                        handleRender={(origin, props) =>
                            React.cloneElement<React.HTMLAttributes<HTMLDivElement>>(origin, {
                                children: props.value >= 1000 ? `${props.value / 1000}km²` : `${props.value}m²`,
                            })
                        }
                        defaultValue={props.filters.plotArea}
                        onAfterChange={(val) => props.setFilters({...props.filters, plotArea: val as [number, number]})}
                        step={10}
                        min={presenter.minPlotArea}
                        max={presenter.maxPlotArea}
                    />
                </div>
            </div>
            <div className="col-12 col-md-6 pb-3">
                <h3>Bouwjaar</h3>
                <div className="col-12">
                    <Slider
                        range
                        handleRender={(origin, props) =>
                            React.cloneElement<React.HTMLAttributes<HTMLDivElement>>(origin, {
                                children: props.value,
                            })
                        }
                        defaultValue={props.filters.buildYear}
                        onAfterChange={(val) =>
                            props.setFilters({...props.filters, buildYear: val as [number, number]})
                        }
                        min={presenter.minBuildYear}
                        max={presenter.maxBuildYear}
                        step={5}
                        marks={presenter.buildYearMarks}
                    />
                </div>
            </div>
            <div className="col-12 col-md-6 pb-3">
                <h3>Eigendom</h3>
                <ReactSelect
                    classNamePrefix="form-multiple-select"
                    className="form-multiple-select"
                    isMulti={true}
                    isClearable={false}
                    options={ownershipOptions}
                    isSearchable={false}
                    value={selectedOwnershipOptions}
                    onChange={(newValue) => {
                        if (newValue) {
                            const sorts = newValue
                                .filter((v) => v.value.startsWith('sorts.'))
                                .map((v) => v.value.replace('sorts.', ''));
                            const durations = newValue
                                .filter((v) => v.value.startsWith('durations.'))
                                .map((v) => v.value.replace('durations.', ''));
                            const other = newValue
                                .filter((v) => v.value.startsWith('other.'))
                                .map((v) => v.value.replace('other.', ''));

                            props.setFilters({...props.filters, ownership: {sorts, durations, other}});
                        } else {
                            props.setFilters({...props.filters, ownership: {sorts: [], durations: [], other: []}});
                        }
                    }}
                    closeMenuOnSelect={false}
                    closeMenuOnScroll={false}
                    placeholder="Kies een of meer opties..."
                    menuShouldScrollIntoView={false}
                    menuPortalTarget={document.getElementById('react-select-portal')}
                />
            </div>
            <div className="col-12 col-md-6 pb-3">
                <h3>Overige kenmerken</h3>
                <ReactSelect
                    classNamePrefix="form-multiple-select"
                    className="form-multiple-select"
                    isMulti={true}
                    isClearable={false}
                    options={otherAspectsOptions}
                    isSearchable={false}
                    value={selectedOtherAspectsOptions}
                    onChange={(newValue) => {
                        if (newValue) {
                            const garden = newValue.some((v) => v.value === 'garden');
                            const shed = newValue.some((v) => v.value === 'shed');
                            const garage = newValue.some((v) => v.value === 'garage');
                            const parking = newValue.some((v) => v.value === 'parking');
                            const solar = newValue.some((v) => v.value === 'solar');

                            props.setFilters({...props.filters, otherAspects: {garden, parking, solar, shed, garage}});
                        } else {
                            props.setFilters({...props.filters, otherAspects: {}});
                        }
                    }}
                    closeMenuOnSelect={false}
                    closeMenuOnScroll={false}
                    placeholder="Kies een of meer opties..."
                    menuShouldScrollIntoView={false}
                    menuPortalTarget={document.getElementById('react-select-portal')}
                />
            </div>
        </div>
    );
});
