import Driver from 'driver.js';

interface StepDefinition extends Driver.Step {
    popover: Driver.Step['popover'] & {
        identifier: string | null;
    };
}

/**
 * Get all steps for selector
 */
export function getTooltipSteps(selector: string) {
    const stepDefinitions: StepDefinition[] = [];
    document.querySelectorAll(selector).forEach(function (el: Element) {
        stepDefinitions.push({
            element: `[data-tooltip="${el.getAttribute('data-tooltip')}"]`,
            popover: {
                identifier: el.getAttribute('data-tooltip'),
                title: el.getAttribute('data-tooltip-title') ?? undefined,
                description: el.getAttribute('data-tooltip-description') ?? '',
                position: el.getAttribute('data-tooltip-position') ?? undefined,
            },
        });
    });
    if (document.querySelectorAll('[data-tooltip-single]').length !== 0) {
        const singleStep = document.querySelectorAll('[data-tooltip-single]')[0];
        if (singleStep) {
            const singleStepValue = singleStep.getAttribute('data-tooltip-single');

            return stepDefinitions.filter((s) => s.popover.identifier === singleStepValue);
        }
    }
    return stepDefinitions;
}
